<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :model="formData"
        inline
      >
        <el-form-item prop="dateRange">
          <el-date-picker
            v-model="formData.dateRange"
            type="daterange"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          />
        </el-form-item>
        <el-form-item prop="explicit">
          <span>应用名: </span>
          <el-select
            v-model="formData.app_key"
            placeholder="选择APP"
            collapse-tags
            clearable
            @change="handleSearch"
          >
            <el-option
              label="全部"
              value=""
            />
            <el-option
              v-for="(item, index) in appKeysList"
              :key="index"
              :label="item.name"
              :value="item.app_key"
            />
          </el-select>
        </el-form-item>
        <!-- 支付方式 -->
        <el-form-item prop="pay_type">
          <span>支付类型: </span>
          <el-select
            v-model="formData.pay_type"
            style="width: 308px"
            multiple
            clearable
            filterable
            collapse-tags
            placeholder="选择支付类型"
          >
            <el-option
              v-for="(item, index) in channelList"
              :key="index"
              :label="item.name"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item prop="goods_name">
          <el-input
            v-model="formData.goods_name"
            placeholder="请输入商品名称"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="handleSearch"
          >
            搜索
          </el-button>
        </el-form-item>
      </el-form>
    </BHeader>
    <!-- v-loading="listInfo.loading" -->
    <div

      class="overview"
    >
      <div class="items">
        <div class="num">
          {{ subscription || '0' }}
        </div>
        <div class="name">
          新增订阅人数（时间段内积累）
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ renewal || '0' }}%
        </div>
        <div class="name">
          续订率（时间段内平均）
        </div>
      </div>
      <div class="items">
        <div class="num">
          {{ active || '0' }}
        </div>
        <div class="name">
          当前活跃订阅人数
        </div>
      </div>
    </div>
    <!-- v-loading="listInfo.loading" -->
    <el-table
      :data="listInfo.list"
      style="width: 100%"
    >
      <el-table-column
        label="日期"
        prop="date"
        min-width="120px"
      />
      <el-table-column
        label="应用名"
        prop="app_key"
        min-width="130px"
      />
      <el-table-column
        label="支付渠道"
        prop="pay_type"
        min-width="180px"
      />
      <el-table-column
        label="商品名"
        prop="goods_name"
        min-width="120px"
      />
      <el-table-column
        label="新增订阅人数"
        prop="new_user"
        min-width="120px"
      />
      <el-table-column
        label="新增订阅金额"
        prop="new_amount"
        min-width="100px"
      />
      <el-table-column
        label="续订人数"
        prop="renewal_user"
        width="180px"
      />
      <el-table-column
        label="续订金额"
        prop="renewal_amount"
        width="180px"
      />
      <!-- <el-table-column
        label="续订率"
        prop="renewal_table"
        width="180px"
      /> -->
      <el-table-column
        label="续订率"
      >
        <template slot-scope="scope">
          {{ scope.row.renewal_table }} %
        </template>
      </el-table-column>
    </el-table>
  </Layout>
</template>

<script>
import { format } from 'fecha'
import { commonRequestGet } from '@/api/commonApi.js'
import {
  cloneDeep
} from 'lodash'
const getLast7Days = () => {
  const today = new Date()
  const targetDay = new Date(today.getTime() - 6 * 24 * 60 * 60 * 1000).getTime()
  return [format(targetDay, 'YYYY-MM-DD'), format(today, 'YYYY-MM-DD')]
}
// const getYesterday = () => {
//   const hours = new Date().getHours()
//   // 当天上午9点以后, 默认时间查询昨天信息, 否则查询前天
//   const days = hours > 9 ? 1 : 2
//   const targetDay = new Date((Date.now() - days * 24 * 60 * 60 * 1000)).getTime()
//   return [format(targetDay, 'YYYY-MM-DD'), format(targetDay, 'YYYY-MM-DD')]
// }
const regionTypeObj = {
  all: '全部国家',
  display: '部分国家显示',
  hide: '部分国家隐藏'
}
export default {
  name: 'SubscriptionDataQuery',
  data () {
    return {
      regionTypeObj,
      subscription: '',
      renewal: '',
      active: '',
      pay_name: '',
      renewal_table: '',
      appKeysList: [],
      channelList: [
        // {
        //   name: 'All Channel',
        //   value: 3
        // },
        // {
        //   name: '全部',
        //   value: 0
        // },
        {
          name: 'Google Play',
          value: 1
        },
        {
          name: 'IOS 内购',
          value: 2
        },
        {
          name: 'Stripe',
          value: 4
        },
        {
          name: 'Center Pay',
          value: 5
        },
        {
          name: 'Payer Max (北京时间12:00更新昨日数据)',
          value: 6
        }
      ],
      formData: {
        dateRange: getLast7Days(),
        pay_type: [],
        app_key: '',
        goods_name: '',
        pageNum: 1,
        pageSize: 20
      },
      pickerOptions: {
        disabledDate (time) {
          const hours = new Date().getHours()
          const days = hours > 9 ? 1 : 2
          const dateTime = time.getTime()
          return dateTime < new Date('2020-12-31').getTime() || dateTime > new Date((Date.now() - days * 24 * 60 * 60 * 1000)).getTime()
        }
      },
      listInfo: {
        list: [],
        total: 0,
        sumRevenue: 0,
        sumCost: 0,
        sumRoi: 0,
        loading: true
      }
    }
  },
  created () {
    this.getAppKeys()
    this.getList()
  },
  methods: {
    formatQuery (formData) {
      console.log(formData, 'formatQuery')
      const form = cloneDeep(formData)
      const [startTime, endTime] = form.dateRange
      delete form.dateRange
      return {
        ...form,
        start_time: startTime,
        end_time: endTime
      }
    },
    async getList () {
      try {
        this.listInfo.loading = true
        const query = this.formatQuery(this.formData)
        const res = await commonRequestGet('admin/statistics/subscription-renewal-rate', query)
        console.log('res:', res)
        this.subscription = res.new_user
        this.renewal = (res.renewal_user / res.up_for_renewal_user * 100).toFixed(2)
        this.active = res.active_subscription_user
        this.listInfo.list = res.data
        // 对于每个对象，通过 app_key 查找对应的名字
        this.listInfo.list = res.data.map(item => {
          const appName = this.appKeysList.find(app => app.app_key === item.app_key)?.name
          const payType = this.channelList.find(channel => channel.value === item.pay_type)?.name
          let renewal
          if (item.up_for_renewal_user !== null && item.up_for_renewal_user !== 0) {
            renewal = (item.renewal_user / item.up_for_renewal_user * 100).toFixed(2)
          } else {
            renewal = 0
          }
          return {
            ...item,
            app_key: appName || '',
            pay_type: payType || '',
            renewal_table: renewal || 0
          }
        })
      } catch (err) {
        console.log(err)
        this.listInfo.loading = false
      }
    },
    handleSearch () {
      this.getList()
    },
    async getAppKeys () {
      const res = await commonRequestGet('admin/app-review')
      this.appKeysList = res?.data ?? []
      // 当排序设置为0时，不在应用下拉框内显示
      this.filteredAppKeysList = this.appKeysList.filter(item => item.rank !== 0)
      this.appKeysList = this.filteredAppKeysList.map(item => {
        return {
          app_key: item.app_id,
          name: item.app_name
        }
      })
      this.appKeysNameMap = this.appKeysList.reduce((result, item) => {
        result[item.app_key] = item.name
        return result
      }, {})
    }
  }

}
</script>

  <style lang="less" scoped>
    .overview {
      display: flex;
      // padding: 0 0 20px 0;
      min-width: 880px;
      margin-bottom: 10px;
      .items {
        // width: 210px;
        padding: 0 30px;
        height: 100px;
        box-shadow: 0 0 2px 1px rgba(0, 0, 0, .12);
        border-radius: 4px;
        margin-right: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .num {
          font-size: 26px;
          margin-bottom: 10px;
          .unit {
            font-size: 14px;
          }
        }
        .name {
          font-size: 12px;
        }
      }
      }
      .pre-header {
        height: 46px;
        .select-all {
          position: absolute;
          top: 0;
          z-index: 100;
          width: 100%;
          font-size: 14px;
          margin: 10px 0;
          padding: 10px 20px;
          cursor: pointer;
          background: #F5F7FA;
          box-sizing: border-box;
          box-shadow: 0 0 4px 1px rgba(0, 0, 0, .12);
          &::before {
            content: '';
            display: block;
            height: 10px;
            background: white;
            position: absolute;
            top: -10px;
            left: 0;
            width: 100%;
          }
          &:hover {
            color: cornflowerblue;
          }
        }
      }
  .download-icon {
    display: flex;
    align-items: center;
    .icondown {
      font-size: 35px;
    }
  }
  </style>
